* {
  margin: 0;
  padding: 0;
}

.logo {
  block-size: 100vh;
  display: block;
  inline-size: 100vw;
  object-fit: contain;
}